const messages = {
  headline_used_in_articles: 'Použito v článku',
  article_not_found: 'Galerie není použita v žádném článku.',
  article_exists: 'Galerie je použita v článku !',
  image_count: 'Počet obrázků',
  notify: {
    created: 'galerie byla vytvořena.',
    not_created: 'galerie nebyla vytvořena.'
  },
  reselect: 'Změnit galerii',
  select: 'Vybrat galerii',
  created: 'Vytvořené',
  public_gallery: 'Galerie je veřejná',
  title_placeholder: 'Napište název galerie',
  main_tab: 'Hlavní informace',
  gallery_tab: 'Galerie'
}

export default messages

const messages = {
  list: {
    id: 'Id',
    title: 'Otázka',
    type: 'Typ',
    hidden: 'Skrytý',
    enabled_voting: 'Povolit hlasování',
    category: 'Category',
    created_at: 'Datum vytvoření',
    modified_at: 'Datum poslední změny',
    created_by: 'Vytvořil'
  },
  filter: {
    title: 'Otázka',
    id: 'Id',
    type: 'Type',
    category: 'Category',
    search: 'Hledat'
  },
  id: 'Id',
  title: 'Otázka',
  title_placeholder: 'Napište název ankety',
  city: 'Město',
  description: 'Popis',
  type: 'Typ',
  hidden: 'Skrytý',
  image: 'Obrázek',
  enabled_voting: 'Povolit hlasování',
  category: 'Kategorie',
  created_at: 'Datum vytvoření',
  modified_at: 'Datum poslední změny',
  created_by: 'Vytvořil',
  display_type: 'Způsob zobrazení výsledků',
  restriction_type: 'Omezení hlasování',
  order_date: 'Datum zobrazení na webu',
  end_of_voting: 'Ukončení hlasování',
  published_until: 'Publikováno do',
  postpone: 'Odložit změnu',
  published_since: 'Datum zobrazení na webu',
  select_date: 'Vyberte datum',
  options: 'Možnosti',
  option: 'Možnost',
  option_title: 'Titulek *',
  option_image: 'Obrázek',
  option_position: 'Pozice',
  option_votes: 'Počet hlasů',
  option_like: 'Líbí',
  option_dislike: 'Nelíbí',
  add_option: 'Přidat možnost',
  remove_option: 'Smazat možnost',
  total_votes: 'Počet hlasů celkem:',
  migrated_id: 'Migrated ID',
  slug_url: 'Slug URL',
  button_manage_votes: 'Upravit hlasy',
  results: 'Výsledky ankety',
  management: {
    no_poll_selected: 'Nie je vybraná žiadna anketa',
    create_poll: 'Vytvoriť anketu',
    assign_poll: 'Priradiť anketu',
    remove_poll: 'Odstrániť anketu'
  },
  poll_type: {
    poll: 'Anketa',
    like_dislike: 'Like/Dislike',
    results: 'Výsledek bez hlasování'
  }
}

export default messages

import Store from '@/store'

export const dataLayerVirtualPageView = () => {
  if (Store.getters.vlm) {
    return
  }

  // Ensure window.dataLayer is initialized as an array if not already
  window.dataLayer = window.dataLayer || []

  window.dataLayer.push({
    event: 'virtual_page_view'
  })
}

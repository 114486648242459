const messages = {
  blocks: {
    carTableBlock: {
      title: 'Tabuľka vozidla',
      empty: 'Vložiť tabuľku vozidla',
      notEmpty: 'Je vložená tabuľka s ID:'
    },
    codeBlock: {
      title: 'Embed zdrojového kódu',
      placeholder: 'Napíšte alebo vložte kód...'
    },
    thirdPartyEmbedBlock: {
      title: 'Social media embed',
      empty: 'Vložiť social media embed',
      notEmpty: 'Social media embed'
    },
    headingBlock: {
      title: 'Nadpisy',
      placeholder: 'Váš medzititulok...'
    },
    imageBlock: {
      deletePhoto: 'Zmeniť obrázok',
      selectPhoto: 'Vybrať obrázok',
      title: 'Obrázok',
      uploadPhoto: 'Nahrať obrázok',
      uploadPhotoFromUrl: 'URL fotky',
      author: 'Autor'
    },
    infoBoxBlock: {
      title: 'Infobox',
      empty: 'Vložiť infobox',
      notEmpty: 'Je vložený infobox'
    },
    infographicBlock: {
      title: 'Infografika',
      empty: 'Vložiť infografiku'
    },
    linkToGalleryBlock: {
      title: 'Odkaz na galériu',
      page: 'Číslo strany – poradové číslo obrázka v galérii obrázkov v tomto článku',
      text: 'Text odkazu na danú stranu – text odkazu na zadané číslo strany',
      relatedArticleGalleryButton: 'Vložiť súvisiacu galériu'
    },
    onlineSportMatchBlock: {
      title: 'Online zápas',
      matchId: 'ID zápasu',
      matchType: 'Kategória',
      categoryOther: 'Ostatné',
      categoryHockey: 'Hokej',
      categoryFootball: 'Futbal',
      categoryTennis: 'Tenis'
    },
    orderedListBlock: {
      title: 'Číslovaný zoznam'
    },
    pageBreakBlock: {
      title: 'Zalomenie strany',
      notEmpty: 'Je vložený zlom strany',
      desc: 'Zlom strany (pokračovanie na ďalšej strane)'
    },
    paragraphBlock: {
      title: 'Text',
      placeholder: 'Váš text...'
    },
    placeholderBlock: {
      title: 'Placeholder',
      start: 'Začnite v písaní, alebo pridajte obsahový blok...',
      end: 'Pokračujte v písaní, alebo pridajte obsahový blok...'
    },
    paidContentBreakBlock: {
      title: 'Platený obsah - zlom',
      notEmpty: 'Je vložený zlom plateného obsahu',
      desc: 'Zlom - platený obsah'
    },
    pollBlock: {
      title: 'Anketa',
      empty: 'Vložiť anketu',
      notEmpty: 'Je vložená anketa'
    },
    quizBlock: {
      title: 'Kvíz',
      empty: 'Vložiť kvíz',
      notEmpty: 'Je vložený kvíz'
    },
    quoteBlock: {
      title: 'Citát',
      author: 'Meno autora...',
      text: 'Váš text...'
    },
    sportTableBlock: {
      title: 'Športová tabuľka',
      seasonId: 'Id tabuľky',
      activeTab: 'Aktívny tab (1 = prvý)'
    },
    relatedArticleBlock: {
      title: 'Súvisiaci článok',
      empty: 'Vložiť súvisiaci článok'
    },
    themeBlock: {
      title: 'Témy',
      empty: 'Pridať tému',
      edit_btn: 'Zmeniť tému'
    },
    seriesBlock: {
      title: 'Seriály',
      empty: 'Pridať seriál',
      edit_btn: 'Zmeniť seriál'
    },
    specialArticleGroupBlock: {
      title: 'Špeciály',
      empty: 'Pridať špeciál',
      edit_btn: 'Zmeniť špeciál'
    },
    unorderedListBlock: {
      title: 'Odrážky'
    },
    sourceBlock: {
      title: 'Zdroje'
    },
    videoBlock: {
      title: 'Video',
      empty: 'Vložiť video'
    },
    youtubeBlock: {
      title: 'Youtube video',
      empty: 'Vložiť youtube video'
    },
    tableBlock: {
      title: 'Tabuľka',
      empty: 'Vložiť tabuľku'
    }
  },
  loading: 'Načítavam...',
  btns: {
    btnAddBlock: 'Pridať nový obsahový blok',
    close: 'Zavrieť',
    delete: 'Vymazať'
  },
  modals: {
    btn: {
      confirm: 'Potvrdiť',
      cancel: 'Zrušiť'
    },
    copyPaste: {
      title: 'Vložte Váš obsah zo schránky',
      btnSend: 'Potvrdiť'
    },
    inlineQuote: {
      title: 'Vložiť citovaný zdroj',
      citeLabel: 'Zdroj'
    },
    deleteBlock: {
      title: {
        all: 'Naozaj chcete vymazať <strong>všetky</strong> bloky?',
        single: 'Naozaj chcete vymazať tento blok?'
      }
    },
    selectBlock: {
      title: 'Vyberte obsahový blok do článku'
    },
    link: {
      title: 'Vložiť url adresu',
      urlLabel: 'Url',
      titleLabel: 'Text',
      bodyBlockLabel: 'Odkaz z článku',
      invalidUrl: 'Neplatná url adresa',
      invalidTitle: 'Text nemôže byť prázdny',
      targetLabel: 'Cieľ',
      newWindow: 'V novom okne',
      currentWindow: 'V rovnakom okne',
      relLabel: 'rel',
      notFilled: 'nevyplnené',
      nofollow: 'nofollow',
      btn: {
        confirm: 'Potvrdiť',
        cancel: 'Zrušiť'
      }
    },
    source: {
      title: 'Zdroje článku',
      name: 'Názov zdroja',
      url: 'Odkaz na zdroj',
      addSource: 'Pridať zdroj'
    },
    htmlIdAttr: {
      titleRest: 'Vložiť <a href="https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/id" target="_blank">HTML identifikátor</a> bloku',
      info: 'Technicky môže hodnota atribútu id obsahovať ľubovoľný znak okrem medzier. Aby sa však predišlo neúmyselným chybám, mali by sa používať iba písmená ASCII, číslice, _ a - a hodnota atribútu id by mala začínať písmenom. Napríklad, . má v CSS špeciálny význam (funguje ako selektor triedy). Ak si nedáte pozor na to, aby ste ho zakódovali v CSS, nebude rozpoznaný ako súčasť hodnoty atribútu id. Je ľahké na to zabudnúť, čo vedie k chybám vo vašom kóde, ktoré by bolo ťažké odhaliť.',
      existingLinkWarning: 'Na toto ID sa odkazuje text "{text}" v bloku "{htmlId}". Ak ID upravíte, odkaz prestane fungovať.',
      duplicateId: 'Toto ID už v článku existuje',
      generateUniqueId: 'Vygenerovať unikátne ID'
    },
    thirdPartyEmbed: {
      titleSocialEmbed: 'Social media embed',
      titleInfographic: 'Infografika',
      storyError: 'Do článku nie je možné vložiť Embed typu Story. Vkladajte iba Embed typu Post.',
      inputLabel: 'Vložte URL',
      btn: {
        confirm: 'Vložiť do článku'
      }
    }
  },
  tooltips: {
    addBlock: 'Pridať obsahový blok',
    deleteBlock: 'Vymazať blok',
    deleteBlocks: 'Vymazať všetky bloky',
    editBlock: 'Upraviť blok',
    moveBlock: 'Presunúť blok',
    changeBlock: 'Zmeniť typ bloku',
    copyPaste: 'Vložiť zo schránky',
    htmlIdAttr: 'HTML ID atribút',
    undo: 'Späť',
    redo: 'Znovu',
    bold: 'Tučné',
    code: 'Kód',
    italic: 'Kurzíva',
    link: 'Odkaz',
    unlink: 'Zrušiť odkaz',
    inlineQuote: 'Citovaný zdroj',
    strike: 'Prečiarknuté',
    underline: 'Podčiarknuté',
    insertedTag: 'Aktualizácia textu',
    subscript: 'Dolný index',
    superscript: 'Horný index',
    headingTitle: 'Nadpis {level}. úrovne',
    table: {
      insertRowBefore: 'Vložiť riadok pred',
      insertRowAfter: 'Vložiť riadok za',
      deleteRow: 'Vymazať riadok',
      insertColumnBefore: 'Vložiť stĺpec pred',
      insertColumnAfter: 'Vložiť stĺpec za',
      deleteColumn: 'Vymazať stĺpec',
      mergeCells: 'Zlúčiť bunky',
      splitCells: 'Rozdeliť bunky',
      toggleHeaderRow: 'Prepnúť riadok s hlavičkou',
      toggleHeaderColumn: 'Prepnúť stĺpec s hlavičkou'
    },
    align: {
      left: 'Zarovnať doľava',
      center: 'Zarovnať na stred',
      right: 'Zarovnať doprava'
    }
  }
}

export default messages

import PermissionService from '@/services/PermissionService'

const requiredPermissions =
  PermissionService.REQUIRED_PERMISSIONS.ARTICLE_OFFER_TOPIC_OFFER_PERMISSIONS

const routes = [
  {
    path: 'topicOffer/:id',
    component: () => import('../../components/topic/TopicOffer'),
    name: 'topicOffer_detail',
    meta: {
      description: 'topicOffer detail',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.detailRoute
    }
  },
  {
    path: 'topicOffer',
    component: () => import('../../components/topic/TopicOfferList'),
    name: 'topicOffer_list',
    meta: {
      description: 'TopicOffer list',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.listRoute
    }
  }
]

export default routes

const messages = {
  alternative_title: 'Alternativný titulok pre Seznam',
  title_seznam: 'Titulok pre Seznam',
  generateHtmlFromPrArticle: {
    btn: 'Generovať HTML štruktúru článku',
    modalTitle: 'HTML článku',
    btnCopy: 'Zkopírovať obsah'
  },
  article: 'Článok',
  foundAnError: 'Našla sa chyba',
  articleListError: 'Nastala chyba pri načítaní článkov',
  id: 'Id',
  editorSwitchBtn: {
    oldEditor: 'Prepnúť na nový editor',
    newEditor: 'Prepnúť na starý editor'
  },
  readAlso: 'Prečítajte si tiež',
  commentOnTheTopic: 'Komentár k téme',
  comment: 'Komentár',
  relatedArticle: 'Súvisiaci článok',
  review: 'Recenzia',
  usedInArticle: 'Použité v článku',
  notUsedInAnyArticle: 'Nenachádza sa v žiadnom článku.',
  moreOnTheTopic: 'Viac k téme',
  document_id: 'Document Id',
  hidden: 'Skrytý',
  status: 'Stav článku',
  main_content: 'Hlavný obsah',
  article_type_title: 'Typ článku',
  internal: 'Interný',
  external: 'Externý',
  video: 'Video',
  site: 'Stránka',
  layout: 'Dizajn',
  main_rubric: 'Hlavná rubrika',
  title: 'Titulok',
  short_title: 'Titulok pre URL',
  sub_title: 'SEO titulok',
  first_paragraph: 'Perex',
  multi_drag: 'Hromadný presun obrázkov',
  show_listing_teaser: 'Iný perex na HP / v rubrike',
  listing_teaser: 'Perex na HP / v rubrike',
  ooyala_id: 'Ooyala #ID',
  video_id: 'Video #ID',
  remove_ooyala_id: 'Vymazať Ooyala #ID',
  body_text: 'Text článku',
  body_text_seo_info: '<b>Text článku</b><br>Telo článku by malo byť rozdelené na relatívne krátke odseky, ktoré by mali obsahovať 5-7 riadkov. Odporúčame používať odrážky, zvýrazňovať hlavné myšlienky článku boldom, pracovať s veľkosťami medzititulkov/podnadpisov (H2, H3). V každom článku je podľa možnosti vhodné umiestniť aspoň 4-5 prelinkov na relevantné články z produkcie NMH, označením relevantnej frázy/vety/slovného spojenia a vytvorením prelinku (hypertextového odkazu).',
  hero_image: 'Hlavný obrázok',
  hero_image_display_type: 'Spôsob zobrazenia',
  hero_image_display_type_big: 'Veľký',
  hero_image_display_type_small: 'Malý',
  hero_video: 'Hlavné video',
  hero_video_dynamic_preview: 'Dynamic preview',
  another_image_in_the_list: 'Iný obrázok na HP / v rubrike',
  listing_image: 'Obrázok na HP / v rubrike',
  gallery: 'Galéria',
  related_articles: 'Súvisiace články',
  related_disease_articles: 'Súvisiace ochorenia',
  main_settings: 'Hlavné nastavenia',
  other_rubrics: 'Rubriky',
  order_date: 'Dátum publikovania na webe',
  set_order_date_to_now: 'Nastav na teraz',
  set_published_until: 'Nastaviť vypnutie z webu',
  published_until: 'Publikovať do',
  postpone: 'Odložiť zmenu zverejnenia na webe',
  published_since: 'Publikovať od',
  hiddenOnHp: 'Nepublikovať na HP',
  author: 'Autor',
  authors: 'Autori zobrazení na webe',
  main_author: 'Hlavný autor',
  safety_topics: 'Téma (brand safety)',
  selected_topics: 'Vybrané témy',
  selected_topics_no_keywords: 'Pre priradenie tém je nutné zadať kľúčové slová',
  owners: 'Vlastníci',
  sharedUsers: 'Autorský podiel',
  url: 'Url',
  urls: 'URLs',
  meta_info: 'Ďalšie nastavenia',
  page_titles: 'Názvy stránok stránkovaného článku:',
  page_titles_car_test_generated_info: 'Názvy stránok pre test vozidla sú vygenerované automaticky: 1: Exteriér, 2: Interiér, 3: Pohon, 4: Podvozok, 5: Cena, 6: Záver',
  page_title_add: 'Pridať názov stránky',
  quiz_id: 'Quiz #ID',
  recipe_id: 'Recipe #ID',
  test_id: 'Test #ID',
  test_title: 'Test title',
  quiz_title: 'Quiz title',
  recipe_title: 'Recipe title',
  print_publication: 'Printové vydanie',
  print_publication_category: 'Kategória printového vydania',
  tags: 'Kľúčové slová',
  tags_geneea: 'Geneea kľúčové slová',
  tags_geneea_empty: 'Zatiaľ žiadne kľúčové slová',
  tags_geneea_generate: '+ vygenerovať kľúčové slová',
  tags_geneea_regenerate: '+ pregenerovať kľúčové slová',
  tags_editorial: 'Redakčné tagy',
  create_new_tag: 'Vytvoriť nové',
  create_new_tag_message: 'Nové kľučové slovo bolo vytvorené.',
  flags: 'Značky na webe',
  image_gallery: 'Galéria',
  exclusive: 'Exkluzívne',
  commentary: 'Komentár',
  interview: 'Rozhovor',
  online: 'Online',
  updated: 'Aktualizované',
  updated_until_datetime: 'Aktualizované zobraziť do',
  just_now: 'Práve sa stalo',
  just_now_until_datetime: 'Práve sa stalo zobraziť do',
  poll: 'Anketa',
  photostory: 'Fotostory',
  quiz: 'Kvíz',
  recipe: 'Recept',
  test: 'Test',
  longform: 'Longform',
  like_dislike: 'Páči / Nepáči',
  settings: 'Nastavenia',
  unlocked: 'Odomknuté',
  disable_comments: 'Zrušiť diskusiu',
  disable_transfer: 'Vylúčenie z ponuky obsahu',
  pr: 'PR článok',
  native: 'Natívny článok',
  editorial_article: 'Redakčný článok',
  copied_from_print: 'Preklopené z printu',
  copyId: 'Skopírovať ID do schránky',
  paid_content: 'Zamknutý/Platený obsah',
  proofread_status: 'Proofread status',
  categories: 'Obsahové kategórie',
  video_type: 'Typ videa',
  system_settings: 'Systémové nastavenia',
  article_history: 'História článku',
  article_history_not_found: 'Bez histórie.',
  time: 'Čas',
  type: 'Typ',
  user: 'Používateľ',
  first_published_date: 'Publikované',
  migrated_system: 'Migrated system',
  migrated_id: 'Migrated id',
  migrated_remote_id: 'Migrated remote id',
  unlock_for_edit: 'Odomknúť úpravy',
  edit: 'Upraviť',
  created_at: 'Vytvorené',
  created_by: 'Vytvoril',
  modified_at: 'Zmenené',
  modified_by: 'Úpravil',
  external_url: 'URL',
  external_author: 'Autor',
  external_site_name: 'Meno stránky',
  external_site_url: 'URL adresa stránky',
  external_rubric_name: 'Názov rubriky',
  external_rubric_url: 'URL adresa rubriky',
  last_modified: 'Posledná editácia',
  version_info_published: 'Článok má aj publikovanú verziu. Otvoriť',
  version_info_draft: 'Článok má aj verziu v koncepte. Otvoriť',
  url_link: 'URL',
  url_gallery_link: 'Galéria',
  last_change_info_edited: 'upravil',
  activity_is_editing: 'upravuje článok!',
  antares_info: 'Štatistiky',
  antares_visits: 'Zobrazenia článku',
  antares_info_not_available: 'Štatistika nie je k dispozícii',
  title_is_too_short: 'Titulok je krátky',
  title_is_too_long: 'Titulok je príliš dlhý',
  short_title_is_too_short: 'Titulok pre URL je krátky',
  short_title_is_too_long: 'Titulok pre URL je príliš dlhý',
  seo_title_invalid: 'Titulok musí obsahovať {min} až {max} znakov',
  seo_title_seo_info: '<b>SEO Titulok</b><br>SEO titulok by mal mať dĺžku {min}-{max} znakov, začínať by mal veľkým písmenom primárneho kľúčového slova alebo kombinácie slov (napríklad. Módne trendy 2020, Fico na dovolenke). V SEO titulkoch sa treba vyhýbať prívlastkom ako Neuveriteľné, Šokujúce - skrátka slovám, ktoré nemajú výpovednú hodnotu. SEO titulok by mal krátko a výstižne popisovať, o čom je daný článok. ',
  first_paragraph_is_too_short: 'Perex je krátky',
  first_paragraph_is_too_long: 'Perex je príliš dlhý',
  first_paragraph_seo_info: '<b>Perex - popis článku</b><br>Dĺžka ideálne do 160 znakov. Nemá priamy efekt na SEO, ale výrazne zvyšuje mieru prekliku na stránku - zobrazuje sa totiž pri našich článkoch vo výsledkoch vyhľadávačov. Tak by aj mal byť koncipovaný, nabádať čitateľa na kliknutie.',
  status_draft: 'Koncept',
  status_review: 'Na schválenie',
  status_ready: 'Naplánovaný',
  status_published: 'Publikovaný',
  status_expired: 'Expirovaný',
  status_draft_create: 'Nový koncept',
  status_draft_update: 'Úprava konceptu',
  status_draft_delete: 'Koncept zmazaný',
  status_draft_to_review: 'Poslaný na kontrolu',
  status_review_to_ready: 'Poslaný na publikovanie',
  status_review_to_draft: 'Stiahnutý do konceptu',
  status_ready_to_published: 'Vypublikovaný',
  status_ready_to_draft: 'Stiahnutý do konceptu',
  status_published_to_draft: 'Odpublikovaný do konceptu',
  nature_of_the_article: 'Povaha článku',
  source_type: 'Zdroj článku',
  source_type_internal_author: 'Interný autor pre web',
  source_type_agency_article: 'Agentúrny',
  source_type_external_author: 'Externý autor pre web',
  source_type_transfered_article: 'Prebratý článok',
  source_type_ai_assisted: '🤖 S asistenciou AI',
  ai_assisted_tooltip: 'Označte 1 alebo viac nástrojov, ktoré ste pri tvorbe článku použili. Ak ste použitý nástroj nenašli v zozname, vpíšte ho do položky "Iné". <br>Využitie funkcií s AI, ktoré sú integrované priamo v CMS, je zaznamenávané automaticky.',
  auto_logout_text: 'Vaša práca bola uložená a boli ste odhlásený z článku',
  doctors: 'Odborní garanti/lekári',
  gardeners: 'Odborní garanti/záhradkári',
  flag: {
    counseling_title: 'Článok má poradňu'
  },
  ai_service: {
    status_ai_recommended: 'ODPORÚČANÉ ',
    generate_from_ai: 'Generovať',
    generate_from_ai_again: 'Zatvoriť a generovať znova',
    ai_generated: 'Odporúčané kľúčové slová. Prosím, vyberte:',
    show_generated: 'Zobraziť vygenerované návrhy',
    select_generated: 'Vyberte 1 z odporúčaných perexov (neskôr ho môžete upraviť)',
    replace: 'Použiť ako',
    add_after: 'Pridať za',
    fill_in_the_title: 'Vyplňte pole Titulok',
    not_enough_chars: 'Pridajte ešte {charsLeft} znakov do Textu článku',
    no_generated_results: 'Nepodarilo sa vygenerovať žiadne výsledky.',
    recommended_articles_error_title: 'Je nám ľúto, ale momentálne nemôžeme zobraziť súvisiace články.',
    recommended_articles_error_body: 'Text článku je príliš krátky na to, aby sme mohli poskytnúť relevantné odporúčania. Prosím, skúste článok rozšíriť alebo pridať viac obsahu, aby sme vám mohli ponúknuť vhodné návrhy.',
    recommended_articles_service_unavailable: 'Je nám ľúto, ale momentálne nemôžeme zobraziť súvisiace články. Skúste to znovu neskôr.',
    ai_modal: {
      modal_title_heading: 'A/B/C testing titulkov',
      modal_perex_heading: 'Perex',
      modal_keyword_heading: 'Kľúčové slová',
      modal_title_hint: 'Vyberte B a C verziu titulku.',
      modal_perex_hint: 'Vyberte 1 z odporúčaných perexov (neskôr ho môžete upraviť)',
      modal_keyword_hint: 'Vyberte vhodné kľúčové slová',
      modal_use_button: 'Použiť v článku',
      modal_input_err_min: 'Kľúčové slovo musí obsahovať min. 2 znaky.',
      modal_input_err_duplicate: 'Pole obsahuje kľúčové slovo zhodné s iným poľom.',
      modal_related_modal_tooltip: 'Článok je odporúčaný algoritmom na báze umelej inteligencie, ktorý hľadá najvyššiu zhodu v texte medzi Vašim článkom a ostatnými nedávno publikovanými článkami.',
      modal_socialMediaPost_heading: 'Post na sociálne siete',
      modal_socialMediaPost_hint: 'Vyberte alebo upravte post pre sociálnu sieť.'
    },
    social_media_generator: {
      card_title: 'Sociálne siete',
      card_title_tooltip: 'Táto funkcia vytvára odporúčaný text pre zdieľanie článku na sociálnych sieťach. Návrh je generovaný z poľa Titulok, preto dokáže pracovať len s informáciami, ktoré obsahuje titulok článku.',
      generate_btn: 'Generovať status',
      post_length: {
        title: 'Dĺžka postu',
        title_tooltip: 'Približný počet znakov:<br><br>' +
          'Krátky = 100<br>' +
          'Stredný = 150<br>' +
          'Dlhý = 200',
        short: 'Krátky',
        medium: 'Stredný',
        long: 'Dlhý'
      },
      type_of_interaction: {
        title: 'Typ interakcie',
        title_tooltip: 'Diskusia<br> Podnecuje k zanechaniu komentára<br><br>' +
          'Prečítanie<br> Podnecuje k prečítaniu článku<br><br>' +
          'Zdieľanie<br> Podnecuje k zdieľaniu článku',
        discussion: 'Diskusia',
        reading: 'Prečítanie',
        sharing: 'Zdieľanie'
      }
    }
  },
  ab_test: {
    title: 'A/B/C Testing',
    variant: 'Verzia',
    winner: 'výherná',
    impressions_count: 'Počet zobrazení',
    clicks_count: 'Počet kliknutí',
    ctr: 'CTR (klikanosť)',
    duration: 'Trvanie',
    info: {
      title: 'Aktiváciou A/B/C tetsingu sa automatický po vypublikovaní článku zapne testovanie. Tento proces je plne automatický a po vyhodnotení sa nastaví výherná verzia Titulku a Fotky. Testuje sa na všetkých stránkach NMH a všetkých šablónach',
      image: 'V prípade nenastavenia obrázku sa použije obrázok z variantu A resp. Hlavný obrázok.'
    }
  },
  list: {
    type: 'Typ',
    title: 'Titulok',
    site: 'Stránka',
    main_rubric: 'Hlavná rubrika',
    author: 'Autor',
    order_date: 'Dátum publikovania',
    last_modified: 'Posledná editácia',
    status: 'Stav článku'
  },
  filter: {
    site: 'Stránka',
    rubric: 'Rubrika',
    search_in: 'Hľadať',
    title: 'Titulok',
    fulltext: 'Všade',
    id_url: 'Hľadať podľa UUID / URL',
    published_since: 'Publikované od',
    published_until: 'Publikované do',
    type: 'Typ článku',
    authors: 'Autori',
    status: 'Stav článku',
    tags: 'Kľúčové slová',
    categories: 'Kategórie',
    users: 'Vlastníci',
    search: 'Hľadať',
    reset_filter: 'Zrušiť filter',
    my_last_modified: 'Posledné mnou upravené',
    my_drafts: 'Moje koncepty',
    my_articles: 'Moje články',
    all: 'Všetky',
    all_drafts: 'Všetky koncepty',
    to_review: 'Na schválenie',
    waiting_to_publish: 'Čaká na publikovanie',
    published: 'Publikované',
    just_edited: 'Práve editované'
  },
  transfer_to_export: {
    title: 'Prebrať článok',
    transfer: 'Prebrať',
    transferred_state: 'Prebratý článok',
    open_original: 'Článok bol prebratý. Otvoriť originál'
  },
  modal: {
    related_articles_header: 'Vyber súvisiace články',
    related_articles_header_tag: 'Nastav menovku',
    related_disease_articles_header: 'Vyber súvisiace ochorenia',
    related_disease_articles_header_tag: 'Nastav menovku',
    quiz_header: 'Vyber kvíz',
    recipe_header: 'Vyber recept',
    test_header: 'Vyber test',
    print_publication_header: 'Vyber printové vydanie',
    article_history_header: 'História článku',
    create_new_modal_header: 'Vytvoriť nový článok',
    create_new_modal_create: 'Vytvoriť',
    refresh_url_header: 'Chcete pregenerovať URL článku?',
    before_close_header_1: 'Varovanie! Máte neuložené zmeny.',
    before_close_header_2: 'Chcete odísť?',
    before_close_yes: 'Áno',
    before_close_no: 'Nie',
    set_hero_image_header: 'Naozaj chcete nastaviť Hlavný obrázok?',
    yes: 'Áno',
    no: 'Nie',
    insert_all_ai_generated_related_articles: 'Označiť všetky odporúčané',
    select: 'Vybrať',
    insert: 'Vložiť',
    youtube_header: 'Vložiť youtube video',
    youtube_url: 'Youtube URL adresa / ID videa',
    youtube_url_warning: 'Vybrané video je momentálne nedostupné, ste si istí?',
    youtube_url_unauthorized: 'Autor videa zakázal vkladanie tohto videa na webové stránky.',
    ooyala_header: 'Vložiť ooyala video',
    ooyala_id: 'Ooyala ID',
    ooyala_id_status_warning: 'Video nie je v stave live, chcete ho aj tak pridať?',
    ooyala_id_preview_warning: 'Video nemá náhľadový obrázok !',
    ooyala_id_warning: 'Nesprávne ooyala video id!',
    infobox_header: 'Vložiť Infobox',
    infobox_create: 'Vytvoriť nový Infobox',
    infobox_select: 'Vybrať z existujúcich Infoboxov',
    infobox_create_header: 'Vložiť Infobox',
    infobox_title: 'Titulok',
    infobox_heading: 'Nadpis',
    infobox_bodytext: 'Obsah infoboxu',
    poll_header: 'Vložiť anketu',
    poll_update_votes: 'Upraviť hlasy',
    poll_create: 'Vytvoriť novú anketu',
    poll_select: 'Vybrať z existujúcich ankiet',
    poll_type: 'Typ',
    poll_question: 'Otázka',
    poll_display_type: 'Spôsob zobrazenia',
    poll_restriction_type: 'Obmedzenie hlasovania',
    poll_end_of_voting: 'Ukončenie hlasovania',
    poll_order_date: 'Dátum zobrazenia na webe',
    poll_published_until: 'Publikované do',
    poll_show_published_since: 'Odložiť zmenu',
    poll_published_since: 'Publikované od',
    poll_hidden: 'Skrytý',
    poll_enabled_voting: 'Povoliť hlasovanie',
    poll_options: 'Možnosti',
    poll_option: 'Možnosť',
    poll_option_title: 'Titulok *',
    poll_add_option: 'Pridať možnosť',
    quote_header: 'Vložiť citát',
    quote_title: 'Obsah citátu',
    quote_author: 'Autor citátu',
    sport_table_header: 'Vložiť športovú tabuľku',
    sport_table_seasonId: 'Id tabuľky',
    sport_table_activeTab: 'Aktívny tab (1 = prvý)',
    sport_online_header: 'Vložiť online zápas',
    sport_online_matchId: 'ID zápasu',
    sport_online_category: 'Kategória',
    gallery_link_header: 'Vložiť odkaz na galériu',
    gallery_link_pageNumber: 'Číslo strany',
    gallery_link_linkText: 'Text odkazu',
    html_header: 'Vložiť externý objekt',
    html_code: 'Kód externého objektu',
    html_code_placeholder: 'Vložte kód sem',
    unsaved_version: {
      header: 'Automatické uloženie článku',
      body: 'Systém automaticky uložil rozpracovaný článok. Chcete pokračovať od poslednej zmeny?',
      footer: 'Posledná zmena {lastModifiedText} od {username}',
      lastModifiedText: '{date} o {time}',
      today: 'dnes',
      yesterday: 'včera',
      offline_saved_version: 'Zdá sa, že ste offline. Posledná uložená verzia {lastModifiedText}. Akékoľvek ďalšie zmeny nemusia byť uložené.',
      offline_no_saved_version: 'Zdá sa, že ste offline. Nebola nájdená žiadna automaticky uložená verzia. Akékoľvek ďalšie zmeny nemusia byť uložené.',
      save_log_error: 'Pri automatickom ukladaní článku došlo k chybe. Posledná uložená verzia {lastModifiedText}. Akékoľvek ďalšie zmeny nemusia byť uložené.',
      loading_log_success: 'Automaticky uložené zmeny boli obnovené',
      loading_log_error: 'Automaticky uložené zmeny sa nepodarilo obnoviť',
      yes: 'Áno',
      no: 'Nie'
    },
    save_failed: {
      header: 'Koncept sa neuložil',
      body: 'Koncept sa neuložil. Skúste to znova alebo obnovte poslednú verziu: {lastModifiedText}.',
      save: 'Uložiť',
      restore: 'Obnoviť poslednú verziu'
    }
  },
  preview: {
    article_preview: 'Náhľad článku',
    next: 'Ďalšia',
    prev: 'Predošlá',
    page_no: 'Strana č.',
    preview_error: 'Chyba pri vytváraní náhľadu článku.',
    preview_expired: 'Náhľad expiroval. Pre vygenerovanie nového náhľadu je potrebné otvoriť Úpravy článku a stlačiť tlačidlo:'
  },
  notify: {
    please_fill_all_required_fields: 'Prosím vyplňte správne všetky povinné polia!',
    new_draft_created: 'Nový koncept bol vytvorený',
    new_draft_created_from_published: 'Bol vytvorený nový koncept z publikovaného článku',
    draft_was_saved: 'Koncept bol uložený',
    draft_save_error_500: 'Chyba pri ukladaní článku',
    draft_save_error_loader_stuck: 'Chyba pri ukladaní článku. Ukladanie trvalo dlhšie ako 60 sekúnd a bolo prerušené.',
    draft_save_error: 'Chyba pri ukladaní článku. Prosím skontrolujte si, či nemáte tento článok otvorený v inom tabe.',
    draft_load_error: 'Chyba pri načítaní článku. Zatvorte a skúste znova.',
    article_status_changed_to_draft: 'Stav článku bol zmenený do stavu KONCEPT',
    article_status_changed_to_review: 'Stav článku bol zmenený do stavu NA KONTROLU',
    article_status_changed_to_ready: 'Stav článku bol zmenený do stavu ČAKÁ NA PUBLIKOVANIE',
    article_status_changed_to_ready_forbidden: 'Nemáte oprávnenie na zmenu stavu do ČAKÁ NA PUBLIKOVANIE',
    article_status_changed_to_draft_forbidden: 'Nemáte oprávnenie na zmenu stavu do stavu KONCEPT',
    article_in_draft_exists: 'Článok už má verziu v koncepte!',
    article_in_review_exists: 'Článok už má verziu na schválenie!',
    article_in_ready_exists: 'Článok už má naplánovanú verziu!',
    entity_has_redirect: 'Článok nie je možné odpublikovať, pretože má aktívne Presmerovanie!',
    article_url_refresh_success: 'URL článku bola zmenená',
    article_url_refresh_error: 'Chyba, URL článku nebola zmenená!',
    infobox_created: 'Infobox bol vytvorený',
    poll_created: 'Anketa bola vytvorená',
    youtube_thumbnail_error: 'Nastal problem s dotiahnutim nahľadového obrázku Youtube videa.',
    paidContentEmbedMissing: 'Článok je nastavený ako zamknutý/platený, ale chýba mu bod uzamknutia.',
    paidContentNotChecked: 'Článok má bod uzamknutia, ale nie je nastavený ako zamknutý/platený.',
    paidContentEmbedMultiple: 'Máte nastavených viac bodov uzamknutia, maximum je 1.',
    pageTitlesPageBreaksCount: 'Počet zalomení nesedí s počtom názvov stránok stránkovaného článku',
    missingTags: 'Geneea alebo redakčné tagy'
  },
  article_type: {
    article: 'Interný',
    external: 'Externý',
    video: 'Video',
    quiz: 'Kvíz',
    photostory: 'Fotostory',
    test: 'Test',
    longform: 'Longform',
    recipe: 'Recept',
    disease: 'Ochorenie',
    encyclopedy: 'Encyklopédia',
    onlineReportage: 'Reportáž'
  },
  article_layout: {
    layout_0: 'default',
    layout_1: 'layout 1',
    layout_2: 'layout 2',
    layout_3: 'layout 3'
  },
  test_discriminator: {
    title: 'Typ',
    car: 'Vozidlo',
    restaurant: 'Reštaurácia',
    hotel: 'Hotel'
  },
  notificationService: {
    notification: 'Notifikácia',
    notificationPostponed: 'Publikovať oneskorenú notifikáciu',
    oneSignalNotified: 'Publikovať notifikáciu',
    remainingFromLimit: '{remaining} voľné / {limit} limit'
  },
  hp_topic: {
    add: '+ Pridať ďalší',
    remove: '- Odstrániť',
    approved: 'Schválené vedúcim',
    date: 'Dátum publikovania na Homepage',
    offer: 'Homepage ponuka článkov',
    slot: 'Časový slot (v hod.)'
  }
}

export default messages

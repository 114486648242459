import { isEqual } from 'lodash'
import Store from '@/store'

export const dataLayerUserInfo = (userEmail, userName) => {
  if (Store.getters.vlm) {
    return
  }

  const newUser = {
    user_info: {
      user_email: userEmail,
      user_name: userName
    }
  }

  // Ensure window.dataLayer is initialized as an array if not already
  window.dataLayer = window.dataLayer || []

  // Check if the new user already exists in the dataLayer array using lodash's isEqual function
  const userExists = window.dataLayer.some(user => isEqual(user, newUser))

  // If user doesn't exist, push the new user to the dataLayer array
  if (!userExists) {
    window.dataLayer.push(newUser)
  }
}

import { getMessages } from '@/i18n/i18nHelper'
// !! Separate files are added automatically below, do not import them here.

const messages = {
  youAreLost: 'Ste stratení',
  youAreLostDesc: 'Táto stránka neexistuje',
  takeMeHome: 'Späť na domácu obrazovku',
  home: 'Domov',
  global_search_placeholder: 'Nájdi článok podľa ID / URL',
  create_new_article: 'Pridať nový článok',
  found: 'Nájdené',
  result_not_found: 'Žiadne záznamy',
  page_next: 'Ďalšia',
  page_prev: 'Predošlá',
  page_first: 'Prvá',
  page_last: 'Posledná',
  page_go_to: 'Choď na stranu',
  page_record_number: 'Záznamov na stranu',
  actions: 'Akcie',
  count: 'Počet',
  pick_some: 'Vybrať',
  enter_manually: 'Zadať ručne (enter)',
  type_keyword: 'Tu napíšte kľúčové slovo',
  multiselect_no_result: 'Nenašli sa žiadne výsledky.',
  please_confirm: 'Prosím potvrďte (enter)',
  auto_refresh: 'Automatické obnovenie obsahu',
  search: 'Hľadať',
  reset: 'Resetovať',
  yes: 'Áno',
  no: 'Nie',
  and: 'a',
  reset_filter: 'Zrušiť filter',
  offline_state: 'Zdá sa, že ste offline alebo máte dočasný výpadok internetu. Skontrolujte prosím pripojenie k sieti.',
  ctr: {
    total: 'Celkom',
    mobile: 'Mobil',
    desktop: 'Desktop'
  },
  system: {
    id: 'Id',
    created_at: 'Dátum vytvorenia',
    modified_at: 'Dátum poslednej zmeny',
    created_by: 'Vytvoril',
    modified_by: 'Zmenil'
  },
  buttons: {
    save: 'Uložiť',
    save_and_close: 'Uložiť a zatvoriť',
    save_and_create_new_one: 'Uložiť a vytvoriť nový',
    save_draft: 'Uložiť',
    create: 'Vytvoriť nový',
    delete_button: 'Vymazať',
    usage_button: 'Použitia',
    close: 'Zatvoriť',
    back: 'Späť',
    preview: 'Náhľad',
    edit: 'Upraviť',
    info: 'Info',
    duplicate: 'Duplikovať',
    copy_id: 'Kopírovať ID',
    copy_url: 'Kopírovať URL adresu',
    set_to_now: 'Nastaviť na teraz',
    send_to_draft: 'Vrátiť do konceptu',
    send_to_review: 'Na kontrolu',
    send_to_publish: 'Publikovať',
    edit_as_new_draft: 'Upraviť ako nový koncept',
    unpublish: 'Odpublikovať',
    select_from_bank: 'Vybrať',
    upload_from_computer: 'Nahrať',
    upload_from_url: 'URL',
    upload_from_url_social: 'Vložiť embed',
    upload_from_url_social_desc: '<strong>Vložte URL v tvare napr.</strong> (https://www.instagram.com/p/CV_gh10sfqa <strong>alebo</strong> https://www.youtube.com/watch?v=obkrMiyDrbs)',
    upload_from_youtube: 'Nahrať z Youtube',
    confirm_and_upload: 'Potvrdiť a nahrať',
    image_from_youtube: 'Dotiahnuť obrázok z Youtube',
    media_edit_button: 'Upraviť všetky fotky',
    select_photo_from_bank: 'Vybrať fotku',
    upload_photo_from_computer: 'Nahrať fotku',
    upload_photo_from_url: 'URL fotky',
    upload_photo_from_url_social: 'Embedovať fotku',
    set_address: 'Nastav adresu',
    reset_address: 'Zruš adresu',
    select: 'Vybrať',
    select_article: 'Vyber článok',
    select_articles: 'Vyber články',
    select_disease_article: 'Vyber ochorenie',
    select_disease_articles: 'Vyber ochorenia',
    select_quiz: 'Vyber kvíz',
    select_recipe: 'Vyber recept',
    select_test: 'Vyber test',
    select_print_publication: 'Vyber printové vydanie',
    remove_print_publication: 'Odstráň printové vydanie',
    select_ooayla: 'Vybrať ooyala video',
    select_video: 'Vybrať video',
    select_youtube: 'Vybrať Youtube',
    show_only_selected: 'Zobraziť iba vybrané',
    search: 'Hľadať',
    reset_filter: 'Zrušiť filter',
    reset_selection: 'Odznačiť vybrané',
    bulk_actions: 'Hromadná úprava',
    load_more: 'Načítať viac',
    load_more_items: 'Načítať ďalšie položky',
    content_block_settings: 'Content block nastavenia',
    forward: 'Dopredu',
    backward: 'Dozadu',
    refresh: 'Aktualizovať',
    new_article: 'Nový článok',
    choose_all: 'Vybrať všetky',
    choose_shown: 'Vybrať zobrazené',
    choose_cancel: 'Zrušiť výber',
    cancel: 'Zrušiť',
    insert_selection: 'Vložiť výber'
  },
  modal: {
    media_search_header: 'Vybrať z banky',
    media_upload_header: 'Nahrať z PC',
    media_upload_header_2: 'Nahrať',
    media_url_header: 'Zadajte adresu URL, ktorú chcete načítať',
    reading: 'Načítavam...',
    uploading: 'Nahrávam...',
    uploading_done: 'Nahrané',
    done: 'Hotovo',
    title: 'Titulok',
    keywords: 'Keywords',
    author: 'Autor',
    authors: 'Autori',
    show_author: 'Ukázať autora',
    hidden_caption_on_web: 'Nezobrazovať popis k foto na stránke',
    select: 'Vybrať',
    image_name: 'Názov obrázku',
    image_url: 'Adresa URL obrázka',
    image_url_error_message: 'Na zadanej URL adrese sa nenachádza žiadny obrázok.',
    image_url_error_message_embed: 'Url musí byť v tvare popísanom vyššie',
    pdf_url: 'Adresa URL dokumentu',
    pdf_url_error_message: 'Na zadanej URL adrese sa nenachádza žiadny dokuments.',
    search: 'Hľadať',
    found: 'Nájdené',
    filter: 'Filter',
    tags: 'Kľúčové slová',
    embed_id: 'Id embedu',
    published_since: 'Publikované od',
    published_until: 'Publikované do',
    apply_filter: 'Aktualizovať filter',
    reset: 'Reset',
    load_more: 'Načítať viac',
    site: 'Stránka',
    fulltext: 'Fulltext',
    address_edit: 'Uprava adresy',
    id: 'Id',
    edit_images: 'Zmeň obrázky',
    delete_modal_header: 'Odstrániť tento záznam?',
    delete_modal_text: 'Ste si istí, že chcete odstrániť tento záznam?',
    tag_delete_modal_text: 'Ste si istí, že chcete odstrániť tento záznam? Odstránením tagu sa odstránia aj všetky prepojenia tagu na články a ďalší obsah.',
    delete_modal_cancel_btn: 'Nie',
    delete_modal_confirm_btn: 'Áno, odstrániť tento záznam',
    selected: 'Vybrané',
    usage_modal_header: 'Použitie',
    usage_modal_description: 'Nemôžete vymazať tento záznam, dokiaľ ho neodstránite z nasledovných miest:',
    category: 'Kategória',
    status: 'Status',
    published_count: 'Publikované',
    used_count: 'Počet použití',
    main_info: 'Hlavné informácie',
    meta_info: 'Dodatočné informácie',
    title_is_short: 'Titulok je príliš krátky',
    description: 'Popis',
    export_wood_wing: 'Exportovať do WoodWing',
    download_progress: 'Priebeh sťahovania',
    created_at_from: 'Vytvorené od',
    created_at_to: 'Vytvorené do',
    create_gallery: 'Uložiť galériu',
    cancel_create_gallery: 'Zrušiť',
    insert: 'Vložiť',
    public: 'Verejná',
    instagram: {
      text1: 'Požadovaný Instagram post nie je možné vložiť, pravdepodobne kvôli obmedzeniu na strane Instagramu.',
      text2: 'Post sa však dá vložiť nasledovným spôsobom:',
      text3: '<b>Krok 1:</b> Skopírujte túto upravenú URL adresu postu:',
      text4: '<b>Krok 2:</b> Vo Vašom prehliadači otvorte nový tab, vložte doň túto URL a stlačte ENTER.',
      text5: '<b>Krok 3:</b> V tomto novom tabe sa Vám zobrazí požadovaný obrázok a zmenená URL adresa.',
      text6: 'Túto novú URL adresu prosím skopírujte a vložte do políčka nižšie pod týmto textom a slačte button Vložiť.',
      cdnUrlPlaceholder: 'Tu vložte novú URL adresu...',
      cdnUrlError: 'Instagram túto URL nerozpoznal. Skúste znova postup od kroku 1.',
      previewInfo: 'Ak sa Vám nezobrazuje náhľad postu, zrejme ste zadali zlú URL adresu.'
    },
    journalsselection: {
      title: 'Vybrať Deník(y)',
      search_label: 'Vyhľadať Deník',
      search_placeholder: 'Napíšte názov Deníku alebo skupiny',
      list_title: 'Zoznam Deníkov',
      choosen_title: 'Vybrané Deníky',
      sites_label: 'Publikovať na Denníky (prepíše aktuálne publikované panely na danej pozícií)',
      sites_button_label: 'Vybrať'
    }
  },
  notify: {
    id_was_copied: 'ID bolo skopírované do schránky',
    url_was_copied: 'URL bola skopírovaná do schránky',
    please_fill_all_required_fields: 'Prosím vyplňte správne všetky povinné polia!',
    please_save_all_annotations: 'Prosím uložte všetky anotácie!',
    record_was_created: 'Záznam bol vytvorený',
    record_was_updated: 'Záznam bol zmenený',
    record_was_published: 'Záznam bol publikovaný',
    record_was_unpublished: 'Záznam bol odpublikovaný',
    records_were_updated: 'Záznamy boli zmenené',
    record_was_deleted: 'Záznam bol zmazaný!',
    record_not_deleted: 'Záznam nebol zmazaný!',
    record_in_use_cannot_be_deleted: 'Záznam nie je možné zmazať, pretože je používaný!',
    media_metadata_was_updated: 'Popisy obrázku boli zmenené',
    extract_timeout_error: 'Nepodarilo sa načítať všetky fotografie. Skúste ich nahrávať v menšom počte.',
    upload_timeout_error: 'Nepodarilo sa nahrať všetky fotografie.',
    loading: 'Počkajte...',
    please_check_thumbnail_value: 'Prosím skontrolujte hodnotu pre thumbnail',
    please_check_max_text_length: 'Prosím skontrolujte maximálnu dĺžku textu'
  },
  media: {
    author: 'Autor',
    headline: 'Titulok',
    caption: 'Nadpis',
    description: 'Popis',
    keywords: 'Kľúčové slová',
    created: 'Vytvorené',
    actions: 'Akcie',
    try_upload_again: 'Pokúsiť sa nahrať nenahrané súbory znova',
    try_again: 'Pokúsiť sa znova',
    failed_uploaded_files: 'Tieto súbory sa nepodarilo nahrať:',
    metadata_info_header: 'Metadata info',
    button_edit_metadata: 'Upraviť metadata',
    button_remove: 'Odstrániť',
    button_add_media_to_gallery: 'Pridať do galérie',
    button_add_media_to_bodytext: 'Presunúť do tela článku',
    button_crop: 'Bod záujmu/orez',
    button_download: 'Stiahnuť original',
    button_download_zip: 'Stiahnuť (.zip)',
    button_open_preview: 'Otvoriť náhľad',
    add_pagebreak_text: 'Zlom strany za každou fotkou',
    predetermined_values: 'Predurčené hodnoty',
    remove_all_titles: 'Vymazať všetky titulky',
    set_predetermined_titles: 'Nastav predurčené hodnoty',
    select_image: 'Vyberte obrázok',
    select_photo: 'Vyberte fotku',
    select_ooyala: 'Vyberte ooyala video',
    select_video: 'Vyberte video',
    set_unpublished: 'Nastaviť vypnutie z webu',
    title_is_too_long: 'Titulok je príliš dlhý. Pri uložení bude orezaný na 500 znakov',
    main_image: 'Hlavný obrázok',
    main_video: 'Hlavné video'
  },
  author: {
    name: 'Meno',
    originName: 'Originálne meno',
    slug: 'Slug',
    position: 'Pozícia',
    description: 'Popis',
    email: 'Email',
    image: 'Obrázok',
    type: 'Typ',
    disabled: 'Disabled',
    detailPage: 'Stránka detailu',
    podcast: 'Podcast',
    facebook: 'Facebook',
    google: 'Google',
    twitter: 'Twitter',
    instagram: 'Instagram',
    pinterest: 'Pinterest',
    flourish: 'Flourish',
    tableau: 'Tableau',
    infogram: 'Infogram'
  },
  version: {
    header_1: 'Vyšla nová verzia EAGLE Admin.',
    header_2: 'Uložte si prácu a načítajte novú verziu.',
    current_version: 'Aktuálna verzia',
    available_version: 'Nová verzia',
    load_new_version: 'Načítať novú verziu'
  },
  input_char_count: 'Počet znakov',
  source: 'Zdroj',
  input_char_delimiter: 'z'
}

const files = require.context(
  '.', // current directory
  true, // subdirectories
  /.+\.js$/ // only .js
)

const allMessages = getMessages(files, messages)
export default allMessages

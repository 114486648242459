import { getMessages } from '@/i18n/i18nHelper'
// !! Separate files are added automatically below, do not import them here.

const messages = {
  youAreLost: 'You are lost',
  youAreLostDesc: 'This page doesn\'t exist',
  takeMeHome: 'Take me home',
  home: 'Home',
  global_search_placeholder: 'Search & enter',
  create_new_article: 'Add a new article',
  found: 'Found',
  result_not_found: 'Result not found !',
  page_next: 'Next',
  page_prev: 'Prev',
  page_first: 'First',
  page_last: 'Last',
  page_go_to: 'Go to page',
  page_record_number: 'Records number',
  actions: 'Actions',
  count: 'Count',
  pick_some: 'Pick some',
  enter_manually: 'Enter manually (enter)',
  type_keyword: 'Type a keyword here',
  multiselect_no_result: 'Oops! No elements found. Consider changing the search query.',
  please_confirm: 'Please confirm (enter)',
  auto_refresh: 'Auto-refresh',
  search: 'Search',
  reset: 'Reset',
  yes: 'Yes,',
  no: 'No',
  and: 'and',
  reset_filter: 'Reset filter',
  offline_state: 'You seem to be offline or have a temporary internet outage. Please check your network connection.',
  ctr: {
    total: 'Total',
    mobile: 'Mobile',
    desktop: 'Desktop'
  },
  system: {
    id: 'Id',
    created_at: 'Created at',
    created_by: 'Created by',
    modified_at: 'Modified at',
    modified_by: 'Modified by'
  },
  buttons: {
    save: 'Save',
    save_and_close: 'Save and close',
    save_and_create_new_one: 'Save and create a new one',
    save_draft: 'Save',
    create: 'Create new',
    delete_button: 'Delete',
    usage_button: 'Usage',
    close: 'Close',
    back: 'Back',
    preview: 'Preview',
    edit: 'Edit',
    info: 'Info',
    duplicate: 'Duplicate',
    copy_id: 'Copy ID',
    copy_url: 'Copy URL address',
    set_to_now: 'Set to now',
    send_to_draft: 'Send to draft',
    send_to_review: 'Send to review',
    send_to_publish: 'Publish',
    edit_as_new_draft: 'Edit as new draft',
    unpublish: 'Unpublish',
    select_from_bank: 'Select',
    upload_from_computer: 'Upload',
    upload_from_url: 'URL',
    upload_from_url_social: 'Include embed',
    upload_from_url_social_desc: '<strong>Enter a URL in the form e.g.</strong> (https://www.instagram.com/p/CV_gh10sfqa <strong>or</strong> https://www.youtube.com/watch?v=obkrMiyDrbs)',
    upload_from_youtube: 'Upload from Youtube',
    confirm_and_upload: 'Confirm and upload',
    image_from_youtube: 'Youtube thumbnail',
    media_edit_button: 'Edit all images',
    select_photo_from_bank: 'Select photo',
    upload_photo_from_computer: 'Upload photo',
    upload_photo_from_url: 'Photo URL',
    upload_photo_from_url_social: 'Embed photo',
    set_address: 'Set address',
    reset_address: 'Reset address',
    select: 'Select',
    select_article: 'Select article',
    select_articles: 'Select articles',
    select_disease_article: 'Select disease',
    select_disease_articles: 'Select diseases',
    select_quiz: 'Select quiz',
    select_recipe: 'Select recipe',
    select_test: 'Select test',
    select_print_publication: 'Vyber printové vydanie',
    remove_print_publication: 'Odstráň printové vydanie',
    select_ooayla: 'Select ooyala video',
    select_video: 'Select video',
    select_youtube: 'Select Youtube',
    show_only_selected: 'Show only selected',
    search: 'Search',
    reset_filter: 'Reset filter',
    reset_selection: 'Reset selection',
    bulk_actions: 'Bulk edit',
    load_more: 'Load more',
    load_more_items: 'Load more items',
    content_block_settings: 'Content block settings',
    forward: 'Forward',
    backward: 'Backward',
    refresh: 'Refresh',
    new_article: 'New article',
    choose_all: 'Choose all',
    choose_shown: 'Choose shown',
    choose_cancel: 'Cancel',
    cancel: 'Cancel',
    insert_selection: 'Insert selection'
  },
  modal: {
    media_search_header: 'Select images from bank',
    media_upload_header: 'Upload from computer',
    media_upload_header_2: 'Upload',
    media_url_header: 'Zadajte adresu URL, ktorú chcete načítať',
    reading: 'Reading...',
    uploading: 'Uploading...',
    uploading_done: 'Uploaded',
    done: 'Done',
    title: 'Title',
    keywords: 'Keywords',
    author: 'Author',
    authors: 'Authors',
    show_author: 'Show author',
    hidden_caption_on_web: 'Nezobrazovať popis k foto na stránke',
    select: 'Select',
    image_name: 'Image name',
    image_url: 'Image URL',
    image_url_error_message: 'There is no image at the specified URL',
    image_url_error_message_embed: 'The url must be in the form described above',
    pdf_url: 'Document URL',
    pdf_pdf_error_message: 'There is no document at given URL.',
    search: 'Search',
    found: 'Found',
    filter: 'Filter',
    tags: 'Tags',
    embed_id: 'Embed id',
    published_since: 'Published since',
    published_until: 'Published until',
    apply_filter: 'Apply filter',
    reset: 'Reset',
    load_more: 'Load more',
    site: 'Site',
    fulltext: 'Fulltext',
    address_edit: 'Edit address',
    id: 'Id',
    edit_images: 'Edit images',
    delete_modal_header: 'Delete this record ?',
    delete_modal_text: 'Are you sure you want to delete this record?',
    tag_delete_modal_text: 'Are you sure you want to delete this record? Deleting the tag will also remove all connections of the tag to articles and other content.',
    delete_modal_cancel_btn: 'Close',
    delete_modal_confirm_btn: 'Yes, delete this record',
    selected: 'Vybrané',
    usage_modal_header: 'Usage of item',
    usage_modal_description: 'You can\'t remove this record until you remove it from these places:',
    category: 'Category',
    status: 'Status',
    published_count: 'Published',
    used_count: 'Used count',
    main_info: 'Main info',
    meta_info: 'Meta info',
    title_is_short: 'Title is too short',
    description: 'Description',
    export_wood_wing: 'Export into WoodWing',
    download_progress: 'Download progress',
    created_at_from: 'Created from',
    created_at_to: 'Created to',
    create_gallery: 'Create gallery',
    cancel_create_gallery: 'Cancel',
    insert: 'Insert',
    public: 'Public',
    instagram: {
      text1: 'The requested Instagram post cannot be inserted, probably due to a restriction on the Instagram side.',
      text2: 'However, the post can be inserted in the following way:',
      text3: '<b>Step 1:</b> Copy this edited post URL:',
      text4: '<b>Step 2:</b> Open a new tab in your browser, enter this URL and press ENTER.',
      text5: '<b>Step 3:</b> In this new tab, you will see the desired image and the changed URL address.',
      text6: 'Please copy and paste this new URL into the box below this text and click the Insert button.',
      cdnUrlPlaceholder: 'Insert new URL here...',
      cdnUrlError: 'Instagram did not recognize this URL. Try again from step 1.',
      previewInfo: 'If you do not see a preview of the post, you probably entered a wrong URL.'
    },
    journalsselection: {
      title: 'Select journal(s)',
      search_label: 'Search journal',
      search_placeholder: 'Enter journal name or group',
      list_title: 'List of journals',
      choosen_title: 'Selected journals',
      sites_label: 'Publish on Journals (overwrites currently published panels at given position)',
      sites_button_label: 'Choose'
    }
  },
  notify: {
    id_was_copied: 'ID was copied to clipboard.',
    url_was_copied: 'URL was copied to clipboard.',
    please_fill_all_required_fields: 'Please fill all required fields',
    please_save_all_annotations: 'Please save all annotations!',
    record_was_created: 'Record was created !',
    record_was_updated: 'Record was updated !',
    record_was_published: 'Record was published !',
    record_was_unpublished: 'Record was unpublished !',
    records_were_updated: 'Records were updated !',
    record_was_deleted: 'Record was deleted !',
    record_not_deleted: 'Record was not deleted !',
    record_in_use_cannot_be_deleted: 'The record cannot be deleted because it is in use!',
    media_metadata_was_updated: 'Media metadata was updated !',
    extract_timeout_error: 'Nepodarilo sa načítať všetky fotografie. Skúste ich nahrávať v menšom počte.',
    upload_timeout_error: 'Nepodarilo sa nahrať všetky fotografie.',
    loading: 'Loading...',
    please_check_thumbnail_value: 'Please check correct value for thumbnail',
    please_check_max_text_length: 'Please check max text length'
  },
  media: {
    author: 'Author',
    headline: 'Headline',
    caption: 'Caption',
    description: 'Description',
    keywords: 'Keywords',
    created: 'Created',
    actions: 'Actions',
    try_upload_again: 'Try to upload the failed files again',
    try_again: 'Try again',
    failed_uploaded_files: 'Failed to upload these files:',
    metadata_info_header: 'Metadata info',
    button_edit_metadata: 'Edit metadata',
    button_remove: 'Remove',
    button_add_media_to_gallery: 'Add media to gallery',
    button_add_media_to_bodytext: 'Move media to bodytext',
    button_crop: 'ROI/crop',
    button_download: 'Download original',
    button_download_zip: 'Download (.zip)',
    button_open_preview: 'Open preview',
    add_pagebreak_text: 'Page break below each image',
    predetermined_values: 'Predetermined values',
    remove_all_titles: 'Remove all titles',
    set_predetermined_titles: 'Set predetermined titles',
    select_image: 'Select image',
    select_photo: 'Select photo',
    select_ooyala: 'Select ooyala video',
    select_video: 'Select video',
    set_unpublished: 'Set unpublished',
    title_is_too_long: 'Titulok je príliš dlhý. Pri uložení bude orezaný na 500 znakov.',
    main_image: 'Main image',
    main_video: 'Main video'
  },
  author: {
    name: 'Name',
    originName: 'Origin name',
    slug: 'Slug',
    position: 'Position',
    description: 'Description',
    email: 'Email',
    image: 'Image',
    type: 'Type',
    disabled: 'Disabled',
    detailPage: 'Detail page',
    podcast: 'Podcast',
    facebook: 'Facebook',
    google: 'Google',
    twitter: 'Twitter',
    instagram: 'Instagram',
    pinterest: 'Pinterest',
    flourish: 'Flourish',
    tableau: 'Tableau',
    infogram: 'Infogram'
  },
  version: {
    header_1: 'New version of Eagle Admin available.',
    header_2: 'Save your work and load a new version.',
    current_version: 'Current version',
    available_version: 'Available version',
    load_new_version: 'Load a new version'
  },
  input_char_count: 'Character count',
  source: 'Source',
  input_char_delimiter: 'of'
}

const files = require.context(
  '.', // current directory
  true, // subdirectories
  /.+\.js$/ // only .js
)

const allMessages = getMessages(files, messages)
export default allMessages

import config from '../../config'
import coreApi from '../../api/core'
import GalleryModel from '../../model/GalleryModel'
import GalleryFilterModel from '../../model/GalleryFilter'
import GalleryFilterService from '../../services/gallery/galleryFilterService'
import GalleryService from '../../services/gallery/galleryService'
import _ from 'lodash'

const API_NAME = '/gallery'

const state = {
  error: null,
  detail: GalleryModel,
  list: [],
  totalCount: 0,
  page: 1,
  filter: GalleryFilterModel,
  view: 'expanded',
  previousTabIndex: 0
}

const mutations = {
  storeDetail (state, responseData) {
    state.detail = responseData
  },
  storeList (state, responseData) {
    state.list = responseData.data
    state.totalCount = responseData.totalCount
  },
  addMediaToGallery (state, media) {
    state.detail.expanded.medias.push(media)
  },
  removeMediaFromGallery (state, mediaToRemove) {
    state.detail.expanded.medias = state.detail.expanded.medias.filter(media => media.id !== mediaToRemove.id)
  },
  updateGallery (state, updatedGallery) {
    state.detail.expanded.gallery = updatedGallery
  },
  setError (state, message) {
    state.error = message
  },
  setFilter (state, filter) {
    state.filter = filter
  },
  resetFilter (state) {
    state.filter = _.cloneDeep(GalleryFilterModel)
    state.page = 1
  },
  setPage (state, page) {
    state.page = page
  },
  setView (state, view = 'minimal') {
    state.view = view
  },
  setPreviousTabIndex (state, index) {
    state.previousTabIndex = index
  }
}

const actions = {
  fetch (store) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    const offset = (store.state.page - 1) * config.defaults.list.limit
    const url = API_NAME + '?view=' + store.state.view + '&limit=' + config.defaults.list.limit +
      '&offset=' + offset + GalleryFilterService.buildFilterQuery(store.state.filter) +
      '&order[id]=desc'
    return coreApi().get(url)
      .then(response => {
        store.commit('storeList', response.data)
        store.commit('TOGGLE_LOADING', null, { root: true })
        return Promise.resolve(response.data)
      })
      .catch(error => {
        console.log(error)
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
  },
  fetchOne (store, id) {
    return coreApi().get(API_NAME + '/' + id)
      .then(response => {
        store.commit('storeDetail', response.data)
      })
      .catch(error => console.log(error))
  },
  create (store, gallery) {
    return coreApi().post(API_NAME, JSON.stringify(GalleryService.prepareApiRequest(gallery)))
      .then(response => {
        if (response.status === 201) {
          store.commit('storeDetail', response.data)
          store.commit('setError', null)
          return response.data
        } else {
          store.commit('setError', 'Error')
        }
      })
      .catch(error => {
        if (error.response.status === 500) {
          store.commit('setError', error.response.status)
        } else {
          store.commit('setError', error.response.data.error)
        }
        throw error
      })
  },
  update (store, gallery) {
    return coreApi().put(API_NAME + '/' + gallery.id, JSON.stringify(GalleryService.prepareApiRequest(gallery)))
      .then(response => {
        if (response.status === 200) {
          store.commit('storeDetail', response.data)
          store.commit('setError', null)
        } else {
          store.commit('setError', 'Error')
        }
      })
      .catch(error => {
        if (error.response.status === 500) {
          store.commit('setError', error.response.status)
        } else {
          store.commit('setError', error.response.data)
        }
        throw error
      })
  },
  deleteRecord (store, record) {
    return coreApi().delete(API_NAME + '/' + record.id)
      .then(response => {
        if (response.status === 204) {
          store.commit('setError', null)
        } else {
          store.commit('setError', 'Error')
        }
      })
      .catch(error => {
        if (error.response.status === 500) {
          store.commit('setError', error.response.status)
        } else {
          store.commit('setError', error.response.data)
        }
      })
  }
}

const getters = {
  detail (state) {
    return state.detail
  },
  list (state) {
    return state.list
  },
  totalCount (state) {
    return state.totalCount
  },
  error (state) {
    return state.error
  },
  filter (state) {
    return state.filter
  },
  page (state) {
    return state.page
  },
  previousTabIndex (state) {
    return state.previousTabIndex
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

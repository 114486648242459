const messages = {
  byAverage: 'PV a VV podle průměru',
  kpi_settings: 'Nastavení KPI',
  kpi_settings_department: 'Nastavení KPI pro středisko',
  kpi_basic_view: 'Porovnání s cílem',
  kpi_averages_view: 'Porovnání s průměrem',
  kpi_users_view: 'Zobrazení střediska podle autorů',
  kpi_type: 'Typ KPI',
  vs_kpi: 'vs. KPI',
  department_overall: 'Spolu',
  department: 'Oddělení',
  product: 'Produkt',
  comparison_week: 'Týden',
  kpi_current: 'Reálné plnění',
  kpi_target: 'Nastaveno KPI',
  kpi_percentage: 'Procentuální plnění KPI',
  kpi_median: 'Median odd.',
  kpi_fulfilment: 'Plnění KPI',
  articles_breakdown: 'Typy článků',
  viewsStructure: 'Struktura PV - Typ obsahu',
  print_articles: 'Printové články',
  digital_articles: 'Online články',
  videos: 'Videa',
  video_types: 'Typy Videí',
  performance: 'Výkonnost',
  weights_type: 'Typ vah',
  product_performance: 'Výkonnost produktu',
  department_performance: 'Výkonnost oddělení',
  base_data: 'Podkladová data',
  user_data_not_found: 'Pro daného autora ve vybraném čase neexistují údaje.',
  number_of_records: 'Počet záznamů:',
  info_mark: '*normalizované PV - přepočet PV podle rozdílu monetizačního potenciálu mezi článkovým a galerijním PV, v poměru: <b>1 článkové PV = 14 galerijních PV</b>',
  groupPerformance: {
    orderNumber: 'Pořadí',
    userName: 'Autor',
    departmentName: 'Oddělení',
    printQuality: 'Kvalita Print článků',
    onlineQuality: 'Kvalita Online článků',
    subscriptions: 'Subscriptions',
    socialEngagement: 'Social engagement',
    videoQuality: 'Video kvalita',
    articlesQuantity: 'Kvantita článků',
    videoQuantity: 'Video kvantita',
    nativeQuantity: 'Native quantity',
    total: 'Výkonnost'
  },
  kpi_parameters_tooltip: {
    articles: 'Počet vypublikovaných článků za vybrané období',
    photostory: '',
    quiz: '',
    images: '',
    videos: '',
    pageViews: '',
    pageViewsNormalized: 'Počet PV na článcích, které byly vypublikovány ve zvoleném období, ne PV z historických a nečlánkových šablon',
    videoViews: 'Počet VV na videích v článcích, které byly vypublikovány ve zvoleném období, ne VV z historických a nečlánkových šablon',
    videoArticles: '',
    avgDailyUsers: '',
    avgTimeSpentPerUserArticle: '',
    avgArticleCompletion: '',
    avgTimeSpentPerUserGallery: '',
    avgGalleryCompletion: '',
    avgDailyUsersOrganic: ''
  },
  kpi_parameters: {
    articles: 'Počet článků dohromady',
    photostory: 'Počet fotostory',
    quiz: 'Počet kvízů',
    images: 'Počet obrázků',
    videos: 'Počet uploadovaných videí',
    pageViews: 'Zobrazení sumář (PVs)',
    pageViewsNormalized: ' Zobrazení PV (*normalizované)',
    videoViews: 'Zobrazení videí (VVs)',
    videoArticles: 'Pokrytí článků videem',
    avgDailyUsers: 'Průměrný počet uživatelů (RUs)',
    avgTimeSpentPerUserArticle: 'Průměrná doba strávená na článku',
    avgArticleCompletion: 'Průměrné dočtení článku',
    avgTimeSpentPerUserGallery: 'Průměrná doba strávená v galerii',
    avgGalleryCompletion: 'Průměrná prohlédnutost galerie',
    avgDailyUsersOrganic: 'Průměrná denní organická návštěvnost'
  },
  realTimeStats: {
    pageViews: 'PageViews',
    realUsers: 'Real Users',
    videoViews: 'VideoViews ',
    avgTimeSpent: 'Průměrně strávený čas (ATS)'
  },
  performanceItems: {
    pageViewCnt: 'Zobrazení stránky',
    imagesCnt: 'Obrázky',
    videosCnt: 'Videa',
    fbShares: 'FB sdílení',
    disqusComments: 'Disqus komentar',
    videoViewCnt: 'Zobrazení videí',
    photostoryCnt: 'Fotostory',
    quizCnt: 'Kvíz',
    videoCnt: 'Video',
    internalCnt: 'Interní',
    externalCnt: 'Externí',
    nativeCnt: 'Nativní',
    prCnt: 'PR',
    editorialCnt: 'Redakční',
    galleryViewCnt: 'Zobrazení galerií',
    viewCnt: 'Zobrazení sumář',
    engagedTime: 'Engaged time',
    articleCnt: 'Články',
    articleEditCnt: 'Úpravy článků'
  },
  toolTip: {
    clusterDesc: '<b>Klastr</b> je kategorizovaný soubor titulů',
    weekSelect: '<b>Výběr kalendářního týdne</b><br>Vyberte datum v týdnu pro které se má zobrazit statistika. Vybere se vždy kalendářní týden ohraničený prvním nebo posledním dnem měsíce pokud se takový den v týdnu nachází.',
    orderNumber: 'Pořadí je definováno sloupcem výkonnost',
    printQuality: 'Rozdělení print článků na 3 kategorie: A/B/C',
    onlineQuality:
      `<b>Kombinace základních metrik:</b><br>
      Celkově normalizované PV<br>
      Počet uživatelů<br>
      Čas strávený v článku<br>
      Dočetlost článku<br>
      Čas strávený v galerii<br>
      Dopozeranost galerie<br>
      Organická návštěvnost<br>`,
    subscriptions: 'Počet/hodnota nákupů předplatného online (zatím pouze Trend)',
    socialEngagement:
      `<b>Kombinace základních metrik:</b><br>
      Počet komentářů<br>
      Počet sdílení na Facebooku`,
    videoQuality:
      `<b>Kombinace základních metrik:</b><br>
      Počet přehrání videa<br>
      Přehrání nad polovinu délky videa`,
    articlesQuantity: 'P1/P2 autorský a agenturní, Online autorský, překlopený z printu, agenturní, kopie z jiného titulu, Quiz a Fotostory',
    videoQuantity: 'Počet videí typu: Autorské, Slideshow, Social, Agenturní',
    total: 'Hodnocení celkové výkonnosti autora',
    pageViewsCnt: 'Počet zobrazení stránky (PageViews s GalleryViews)',
    videosCnt: 'Počet videí v článku',
    printType: 'P1/P2 autorský, P1/P2 agenturní',
    actionArticleDetail: 'Zobrazení detailu článku',
    suggestedValue: 'Hodnota je vypočtena na základě premiéru za okno předchozích 12 měsíců'
  },
  weightsSelect: 'Výběr nastavení vah',
  timeGroupType: 'Typ reportu',
  month: 'Měsíc',
  week: 'Týden',
  monthly: 'Měsíční',
  weekly: 'Týdenní',
  language: 'sk',
  save: 'Uložit',
  sumError: 'Součet hodnot editorů musí být roven hodnotě za celé oddělení',
  difference: 'Rozdíl',
  add: 'Přidat',
  remove: 'Odebrat',
  user: 'Uživatel',
  author: 'Autor',
  viewsStructureByDepartment: 'Struktura PV - po odděleních',
  cluster: 'Klastr',
  dateFrom: 'Od',
  dateTo: 'Do',
  average: 'PRŮMĚR',
  department_average: 'Průměr oddělení',
  notAvailable: 'N/A',
  weekShort: 't',
  gcpData: 'GCP Data',
  historicalPerformanceButton: {
    pageViews: 'PVs na reportéra',
    videoViews: 'VVs na reportéra',
    onlineArticles: 'Autorské články na reportéra',
    p1Articles: 'Print P1 na reportéra',
    p2Articles: 'Print P2 na reportéra',
    publishedVideos: 'Vypublikovaná videa na reportéra',
    createdVideos: 'Uploadovaná videa na reportéra',
    imagesInArticles: 'Použité obrázky na reportéra'
  },
  clusterViewMetricsDescription: {
    pageViews: 'Průměr počtu zobrazení stránky na reportéra',
    videoViews: 'Průměr počtu zhlédnutí Videa na reportéra',
    onlineArticles: 'Průměr počtu vypublikovaných Autorských článků na reportéra',
    p1Articles: 'Průměr počtu P1 Printových článků na reportéra',
    p2Articles: 'Průměr počtu P2 Printových článků na reportéra',
    publishedVideos: 'Průměr počtu publikovaných videí na reportéra',
    createdVideos: 'Průměr počtu vytvořených videí na reportéra',
    imagesInArticles: 'Průměr počtu obrázků použitých v článcích na reportéra'
  },
  departmentViewMetricsDescription: {
    pageViews: 'Počet zobrazení stránky na reportéra',
    videoViews: 'Počet přehrání Videa na reportéra',
    onlineArticles: 'Počet vypublikovaných Autorských článků na reportéra',
    p1Articles: 'Počet P1 Printových článků na reportéra',
    p2Articles: 'Počet P2 Printových článků na reportéra',
    publishedVideos: 'Počet publikovaných videí na reportéra',
    createdVideos: 'Počet vytvořených videí na reportéra',
    imagesInArticles: 'Počet obrázků použitých v článcích na reportéra'
  },
  source: 'Zdroj: Eagle-DON',
  productArticlesComposition: 'Složení článků po produktech',
  galleries: 'Galerie',
  articles: 'Články',
  period: 'Období',
  weightsSelection: 'Výběr nastavení vah',
  suggestedValue: 'Navrhovaná hodnota',
  allProducts: 'Celé {company}',
  longLoadingWarning: 'Načtení dat může trvat až 5 minut. Během načítání neodcházejte ze stránky.',
  ai: {
    articleCountTitle: 'Počet článků - Asistence AI',
    pageViewsCountTitle: 'Struktura PV - Asistence AI',
    articleSourceTitle: 'Zdroj článků - Asistence AI',
    withAi: 'S AI',
    withoutAi: 'Bez AI',
    articleSourceAuthor: 'Interní autor pro web',
    articleSourceAuthorAi: 'Interní autor pro web s AI',
    articleSourceAgency: 'Agenturní',
    articleSourceAgencyAi: 'Agenturní s AI',
    articleSourceFromPrint: 'Překlopeno z printu',
    articleSourceFromPrintAi: 'Překlopeno z printu s AI',
    articleSourceTransferred: 'Převzatý',
    articleSourceTransferredAi: 'Převzatý s AI'
  }
}

export default messages

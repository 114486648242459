import moment from 'moment'
import Store from '../../store'
import { MODULE_ARTICLE } from '@/model/ValueObject/UserPermissionModules'

const STATUS_DRAFT = 'draft'
const STATUS_REVIEW = 'review'
const STATUS_READY = 'ready'
const STATUS_PUBLISHED = 'published'

const buildFilterQuery = function (filter) {
  let filterQuery = ''
  if (filter.id) {
    if (filter.id.match('/')) {
      filter.id = filter.id.replace(/\/\d+$/, '')
      filterQuery += '&filter_custom[article.url]=' + filter.id
    } else {
      filterQuery += '&filter_eq[documentId]=' + filter.id
      if (filter.disableTransfer === false) {
        filterQuery += '&filter_eq[setting.disableTransfer]=0'
      }
    }
    return filterQuery
  }
  if (filter.title) {
    if (filter.searchIn === 'title') {
      filterQuery += '&filter_contains[field.title]=' + filter.title
    }
    if (filter.searchIn === 'fulltext') {
      filterQuery += '&filter_custom[article.text]=' + filter.title
    }
  }
  if (filter.site && !filter.rubric) {
    filterQuery += '&filter_eq[site]=' + filter.site
  }
  if (!filter.site && Store.getters['article/searchInEnabledSites']) {
    const enabledSites = Store.getters['site/enabledSites'](MODULE_ARTICLE)
    filterQuery += '&filter_in[site]=' + enabledSites.map(site => site.id)
  }
  if (filter.rubric) {
    filterQuery += '&filter_custom[article.rubricTree]=' + filter.rubric
  }
  if (filter.type && filter.type !== '0' && filter.type !== '') {
    filterQuery += '&filter_eq[type]=' + filter.type
  }
  if (filter.authors && filter.authors.length) {
    filterQuery += '&filter_memberOf[authors]=' + filter.authors.map(author => author.id)
  }
  if (filter.tags && filter.tags.length) {
    filterQuery += '&filter_memberOf[tags]=' + filter.tags.map(tag => tag.id)
  }
  if (filter.disableTransfer === false) {
    filterQuery += '&filter_eq[setting.disableTransfer]=0'
    if (filter.categories && filter.categories.length) {
      filterQuery += '&filter_in[categories]=' + filter.categories.map(category => category.id)
    }
  } else if (filter.categories && filter.categories.length) {
    filterQuery += '&filter_memberOf[categories]=' + filter.categories.map(category => category.id)
  }
  if (filter.users && filter.users.length) {
    filterQuery += '&filter_memberOf[owners]=' + filter.users.map(user => user.id)
  }
  if (filter.status === 'my_last_modified') {
    const currentUser = Store.getters['user/currentUser']
    filterQuery += '&filter_eq[modifiedBy]=' + currentUser.id
  }
  if (filter.status === 'my_drafts') {
    const currentUser = Store.getters['user/currentUser']
    filterQuery += '&filter_memberOf[owners]=' + currentUser.id
    filterQuery += '&filter_eq[setting.status]=' + STATUS_DRAFT
  }
  if (filter.status === 'drafts' || filter.status === 'edited') {
    filterQuery += '&filter_eq[setting.status]=' + STATUS_DRAFT
  }
  if (filter.status === 'my_articles') {
    const currentUser = Store.getters['user/currentUser']
    filterQuery += '&filter_memberOf[owners]=' + currentUser.id
  }
  if (filter.status === 'all') {
    filterQuery += ''
  }
  if (filter.status === 'review') {
    filterQuery += '&filter_eq[setting.status]=' + STATUS_REVIEW
  }
  if (filter.status === 'ready') {
    filterQuery += '&filter_eq[setting.status]=' + STATUS_READY
  }
  if (filter.status === 'published') {
    filterQuery += '&filter_eq[setting.status]=' + STATUS_PUBLISHED
  }
  if (filter.dateFrom) {
    const date = moment(filter.dateFrom).add(-1, 'hours').utc().format('YYYY-MM-DDTHH:mm:ss') + '.000Z'
    filterQuery += '&filter_gte[orderDate]=' + date
  }
  if (filter.dateTo) {
    const date = moment(filter.dateTo).add(23, 'hours').add(-1, 's').utc().format('YYYY-MM-DDTHH:mm:ss') + '.000Z'
    filterQuery += '&filter_lte[orderDate]=' + date
  }
  if (
    filter.dateFrom === null &&
    filter.dateTo === null
  ) {
    // Set default datetime range if filter is not defined
    const dateFrom = moment().add(-3, 'months').utc().format('YYYY-MM-DDTHH:mm:ss') + '.000Z'
    filter.dateFrom = dateFrom
    filterQuery += '&filter_gte[orderDate]=' + dateFrom
  }
  if (filter.documentIds && filter.documentIds.length > 0) {
    filterQuery += '&filter_in[documentId]=' + filter.documentIds
  }
  if (filter.status === 'my_last_modified') {
    filterQuery += '&order[modifiedAt]=desc'
  } else if (Store.getters.vlm && ['my_drafts', 'drafts'].includes(filter.status)) {
    filterQuery += '&order[createdAt]=desc'
  } else {
    filterQuery += '&order[orderDate]=desc'
  }
  if (filter.originalArticle === null) {
    filterQuery += '&filter_null[originalDocumentId]'
  }
  if (filter.isPrArticle === false) {
    filterQuery += '&filter_eq[setting.prArticle]=0'
  }
  return filterQuery
}

export default {
  buildFilterQuery
}
